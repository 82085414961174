
import { defineComponent, ref, watch, onMounted, Ref } from "vue";
import moment from "moment";
import useEmitter from "@/composables/Emmiter";
import AuthService from "@/services/AuthService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/services/ApiService";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import FilterBase from "@/layout/header/partials/filters/FilterBase.vue";
import Api from "@/services/Api";
import { ElConfigProvider } from "element-plus";
import ptBr from "element-plus/lib/locale/lang/pt-br";

interface AttendanceFilter {
  placa: string;
  veiculo: string;
  responsavel: string;
  data: any;
  status: string;
  idAtendimento: string,
  codConcessionariaTeste: number[]
}

export default defineComponent({
  name: "FilterAttendance",
  components: {
    FilterBase,
    ElConfigProvider,
  },

  setup(props, { emit }) {
    const store = useStore();
    const emitter = useEmitter();

    const activeModal = ref(false);
    const closeModal = ref(false);
    const lojas: Ref<any[]> = ref([]);
    const statusAttendance: string[] = ["Aberto", "Enviado", "Fechado", "Vendido", "Cancelado", "Andamento", "Precificar", "Pendente Aprovação"];
    const selectedConcessionarias: Ref<any> = ref([]);

    const attendanceFilter: Ref<AttendanceFilter> = ref({
      placa: "",
      veiculo: "",
      responsavel: "",
      data: "",
      status: "",
      idAtendimento: "",
      codConcessionariaTeste: []
    });

    async function getLojas() {
      const res = await Api.get("getAllStores");
      lojas.value = res.data.lojas;
    }

    function enviarEmit() {
      console.log('na funccaoo', attendanceFilter.value)
      emitter.emit("Filter-Atendimentos", attendanceFilter.value );
      
      closeModal.value = !closeModal.value;
      
    }

    watch(
      ()=> attendanceFilter.value,
      ()=> console.log('oioioi', attendanceFilter.value),
      {deep: true}
    )


    onMounted(() => {
      getLojas()
    });

    return { attendanceFilter, lojas, enviarEmit, closeModal, ElConfigProvider, ptBr, statusAttendance, selectedConcessionarias };
  },
});
